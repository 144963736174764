@import "../../node_modules/@aws-amplify/ui-react/dist/styles.css";

#loginPageSection {
	position: relative;
}

#loginPageSection #rmFooterWrapper.rmLoginPageFooter {
	bottom: 0 !important;
	margin-top: 0 !important;
	width: 100%;
	min-width: initial;
	min-width: auto; /*ie-fix*/
}

#loginPageSection #rmFooterWrapper #rmFooterText {
	width: 70%;
	min-width: 160px !important;
}

@media (max-width: 1280px) {
	#loginPageSection #rmFooterWrapper #rmFooterText {
		font-size: 12px;
		line-height: initial;
		line-height: inherit; /*ie-fix*/
		padding-top: 8px;
		padding-left: 15px;
	}

	#loginPageSection #rmFooterWrapper #rmFooterPrivacy a {
		font-size: 12px;
	}
}

#leftPanePositioning {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: -o-flex;
	display: flex;
	align-items: center;
	padding-top: 60px;
	padding-bottom: 60px;
}

#loginFormWrapper {
	position: relative;
	text-align: center;
	margin: 0 auto;
	width: 100%;
}

#loginRightPane .backgroundImage {
	background-image: url('../static/LoginImage.png');
	background-size: cover;
	background-position: right center;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	bottom: 0px;
	-webkit-filter: blur(1px);
	-moz-filter: blur(1px);
	-o-filter: blur(1px);
	-ms-filter: blur(1px);
	filter: blur(1px);
}

#loginRightPane .imageGradient {
	background: linear-gradient(30deg, rgba(138, 212, 61, 0.5) 0%, rgba(9, 170, 196, 0.5) 100%);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	bottom: 0px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	#loginRightPane .ie-absolute-fix {
		position: relative;
		height: 100%;
	}

	#leftPanePositioning {
		flex: auto;
		-ms-flex-direction: row;
		height: 1px;
	}
}

.logoImage {
    display: block;
    background-position-x: center;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    height: 70px;
    background-image: url('../static/Veritone_logos.svg');
}

.loginMessageTitle {
    font-size: 36px;
    font-weight: 700;
    color: #485465;
    line-height: 49px;
    margin-bottom: 15px;
    text-align: center;
}

.loginMessageText {
    color: #242c37;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 28px;
    width: 100%;
}

.loginMessageContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    text-align: center;
    width: 100%;
}

.amplify-heading--3 {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    text-align: center !important;
    width: 100%;
    font-size: 36px !important;
    font-weight: 700 !important;
    color: #485465 !important;
    line-height: 49px !important;
    margin-bottom: 15px;
    text-align: center;
}

.accountQuestion {
    color: #485465;
    font-size: 16px;
    margin-top: 32px;
}

.contactUsEmail {
    color: #485465;
    font-size: 16px;
}

.contactUsEmail a,
.contactUsEmail a:hover,
.contactUsEmail a:active,
.contactUsEmail a:visited {
    color: #3bb0c9 !important;
    text-decoration: none;
}

#forgotPassLink {
    color: #3bb0c9;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}

.loginBackLink {
    color: #485465;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    margin-top: 35px;
    text-align: center;
    width: 100%;
}

.loginBackLink a {
    color: #3bb0c9;
    cursor: pointer;
    margin-top: 3px;
}

.amplify-passwordfield .amplify-button {
    align-items: center;
    color: #3bb0c9;
    cursor: pointer;
    display: inline-flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    right: 10px;
}

[data-amplify-authenticator] [data-amplify-router] {
    border-width: 0px;
    box-shadow: 0px 0px 0px;
    text-align: left;
}

[data-amplify-authenticator] [data-amplify-form] {
    padding-top: 0px !important;
}

.amplify-button[data-variation=link],
.amplify-button[data-variation=link]:hover,
.amplify-button[data-variation=link]:active,
.amplify-button[data-variation=link]:focus {
    background-color: transparent;
    color: #3bb0c9;
    font-size: 16px;
    border-color: transparent;
    border: none;
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;
}

.amplify-button[data-variation=primary] {
    height: 50px;
    width: 230px;
    color: #fff;
    background-color: #3bb0c9;
    padding: 12px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.18);
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    align-self: center;
    margin: 30px 0 0 0;
}

.amplify-input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px white inset;
	-webkit-text-fill-color: #485465;
}

[data-amplify-theme] {
    --amplify-components-alert-error-color: #485465;
}