@import url("https://fonts.googleapis.com/css?family=Noto+Sans:300,300i,400,400i,600,700|Comfortaa:300");

body {
	background-color: #f8f8f8 !important;
}

body {
	margin: 0;
	padding: 0;
}

body * {
	font-family: 'Noto Sans', sans-serif;
}

body *::selection {
	background-color: #3bb0c9;
	color: #fff;
}

input {
	caret-color: #3bb0c9;
}

#page-header {
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    background-color: #2A323C;
    height: 55px;
    width:100%;
    z-index: 1000;
    padding: 0px 16px;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

#logo {
    width: 150px;
    height: 32px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url('../static/Veritone_Logo_Light.svg');
}

#footer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Loader {
	position: absolute;
	top: 368px;
	text-align: center;
	margin: auto;
	width: 100%;
}

.loadingText {
	width: 100%;
	height: 22px;
	color: #30789f;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	text-align: center;
}

.loadingImg {
	width: 97px;
	height: 100px;
	margin-bottom: 15px;
}

.report-style-class {
	height: calc(100vh - 62px);
}

.report-style-class iframe{
    border-width: 0px 0px 2px 0px;
}

#embedContainer{
	background-color: rgb(229, 229, 229, 0.4);	
}